import styled, { css } from 'styled-components/macro'
import { mq } from 'theme/mediaQueries'

import { Button } from '../Button'

export const StyledIcon = styled.div<{ isExpanded?: boolean }>`
  display: flex;

  ${(p) =>
    p.isExpanded &&
    css`
      transform: rotate(180deg);
    `}
`

export const FlyoutRow = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;

  flex-direction: column;
  padding: 8px;
  border-radius: 8px;

  &:hover {
    background: ${(p) => p.theme.bg4};
  }

  ${(p) =>
    p.isActive &&
    css`
      border: 1px solid ${(p) => p.theme.secondary0};
      background: ${(p) => p.theme.bg4};
    `}

  ${mq.xs} {
    padding: 0;
    flex-direction: row;
    border: none;
    background: transparent;

    & + & {
      margin-top: 24px;
    }
  }
`
export const FlyoutRowActiveIndicator = styled.div`
  background-color: ${({ theme }) => theme.primary1};
  border-radius: 50%;
  height: 4px;
  width: 4px;
  position: absolute;
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
  display: none;

  ${mq.xs} {
    display: block;
    left: auto;
    right: 0;
  }
`

export const Logo = styled.img<{ isActive?: boolean }>`
  height: 48px;
  width: 48px;
  border-radius: 50%;

  ${mq.xs} {
    height: 24px;
    width: 24px;

    ${(p) =>
      p.isActive &&
      css`
        opacity: 0.3;
      `}
  }
`
export const NetworkLabel = styled.div<{ isActive?: boolean }>`
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: 500;

  ${mq.xs} {
    font-size: 16px;
    ${(p) =>
      p.isActive &&
      css`
        opacity: 0.3;
      `}
  }

  ${mq.sm} {
    ${(p) =>
      p.isActive &&
      css`
        opacity: 0.3;
      `}
  }
`

export const StyledNetworkSelector = styled(Button)`
  display: flex;
  align-items: center;
  border: 1px solid ${(p) => p.theme.text1};
  padding: 6px 12px;
  border-radius: 4px;

  ${Logo} {
    width: 20px;
    height: 20px;

    ${mq.xs} {
      width: 24px;
      height: 24px;
    }
  }
`
