import { ChainId } from '@elkdex/sdk'

export const mapChainIdToTokensChainName: { [chainId: number]: string } = {
  [ChainId.KAVA]: 'kava',
  [ChainId.ARBITRUM]: 'arbitrum',
  [ChainId.AVALANCHE]: 'avax',
  [ChainId.BINANCE]: 'bsc',
  [ChainId.CRONOS]: 'cronos',
  [ChainId.ELAETH]: 'elastos',
  [ChainId.ETHEREUM]: 'ethereum',
  [ChainId.FANTOM]: 'ftm',
  [ChainId.FUSE]: 'fuse',
  [ChainId.HARMONY]: 'harmony',
  [ChainId.HUOBIECO]: 'heco',
  [ChainId.HOO]: 'hoo',
  [ChainId.IOTEX]: 'iotex',
  [ChainId.KUCOIN]: 'kcc',
  [ChainId.MATIC]: 'matic',
  [ChainId.MOONRIVER]: 'moonriver',
  [ChainId.OKEX]: 'okex',
  [ChainId.OPTIMISM]: 'optimism',
  [ChainId.TELOS]: 'telos',
  [ChainId.XDAI]: 'xdai',
}

export const mapChainNameToChainId = (chainName: unknown): ChainId => {
  const keys = Object.keys(mapChainIdToTokensChainName)
  const values = Object.values(mapChainIdToTokensChainName)
  const index = values.indexOf(chainName.toString())
  const chainId = (keys[index] as unknown) as ChainId
  return chainId
}
